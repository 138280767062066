import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "info" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "inner d-flex align-items-center justify-content-center" }
const _hoisted_6 = { class: "page info d-flex flex-column with-footer-bar" }
const _hoisted_7 = { class: "white-content mb-4" }
const _hoisted_8 = { class: "content appointments-page" }
const _hoisted_9 = { class: "content flex-grow-1" }
const _hoisted_10 = {
  key: 0,
  class: "header"
}
const _hoisted_11 = {
  key: 0,
  class: "empty-message px-4"
}
const _hoisted_12 = {
  key: 0,
  class: "header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popup = _resolveComponent("popup")!
  const _component_v_calendar = _resolveComponent("v-calendar")!
  const _component_participant_appointment = _resolveComponent("participant-appointment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.infoVisible)
          ? (_openBlock(), _createBlock(_component_popup, { key: 0 }, {
              header: _withCtx(() => [
                _createElementVNode("h2", {
                  innerHTML: _ctx.$t('APPOINTMENTS.POPUP.TITLE')
                }, null, 8, _hoisted_1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "text-center",
                    innerHTML: _ctx.$t('APPOINTMENTS.POPUP.INFO')
                  }, null, 8, _hoisted_3),
                  _createElementVNode("a", {
                    class: "btn btn-md py-2 rounded-pill btn-primary d-block my-4",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.infoVisible = false))
                  }, _toDisplayString(_ctx.$t('APPOINTMENTS.POPUP.GOT_IT')), 1)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("header", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('APPOINTMENTS.PAGE_TITLE')), 1)
      ])
    ]),
    _createElementVNode("main", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_calendar, {
            locale: { id: _ctx.lang, masks: { weekdays: 'WWW', title: 'MMMM' } },
            onDayclick: _ctx.onDayClick,
            attributes: _ctx.calendarAttributes,
            "is-expanded": ""
          }, null, 8, ["locale", "onDayclick", "attributes"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredAppointments, (appointment) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "box-card",
            key: appointment.id
          }, [
            (_ctx.nextAppointment && _ctx.nextAppointment.id === appointment.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('APPOINTMENTS.NEXT_APPOINTMENT')), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_participant_appointment, { appointment: appointment }, null, 8, ["appointment"])
          ]))
        }), 128)),
        (!_ctx.filteredAppointments.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('APPOINTMENTS.NO_APPOINTMENTS_FOR_SELECTION', {date: _ctx.selectedDateFormatted})), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFutureAppointments, (appointment) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "box-card",
            key: appointment.id
          }, [
            (_ctx.nextAppointment && _ctx.nextAppointment.id === appointment.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('APPOINTMENTS.NEXT_APPOINTMENT')), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_participant_appointment, { appointment: appointment }, null, 8, ["appointment"])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}