export const SET_LAYOUT_CONFIG = 'SET_LAYOUT_CONFIG';
export const SET_LOADING = 'SET_LOADING';

import { Module } from "vuex";

const layoutModule: Module<any, any> = {
  namespaced: true,
  state: {
    showPageTitle: false,
    showBackButton: false,
    pageTitle: null,
    pageTitleParams: null,
    backButtonLink: null,
    showFooterNav: false,
  },
  getters: {
  },
  actions: {
  },
  mutations: {
      [SET_LAYOUT_CONFIG](state: any, payload: any) {
        Object.assign(state, payload);
      }
  }
}

export default layoutModule;
