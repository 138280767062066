

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    withExtra: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
  }
})
