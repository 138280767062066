
import { defineComponent } from 'vue'
import { WelcomeSlideData } from '@/model/welcome';
import {ParticipantData} from '@/model/participant-data';

export default defineComponent({
  props: {
    slide: {
      type: Object as () => WelcomeSlideData
    }
  },
  components: {
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lang(): string {
      return this.participantData.language;
    },
  },
  methods: {
  }
})
