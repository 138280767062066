import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page info" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "info-section",
        innerHTML: _ctx.$t('WRONG_ACCESS_MESSAGE')
      }, null, 8, _hoisted_3)
    ])
  ]))
}