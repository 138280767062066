
import { defineComponent } from 'vue'
import {ParticipantData, ParticipantMedicine} from '@/model/participant-data';
import Medicine from '@/components/views/medicines/list-medicines/medicine.vue';

export default defineComponent({
  props: {
  },
  components: {
    Medicine
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    currentMedicines(): ParticipantMedicine[] {
      if (!this.participantData) return [];
      return this.participantData.medications.filter((med) => {
        return !med.is_past;
      })
    },
    pastMedicines(): ParticipantMedicine[] {
      if (!this.participantData) return [];
      return this.participantData.medications.filter((med) => {
        return med.is_past;
      })
    }
  },
  methods: {
  }
})
