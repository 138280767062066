import {
  createRouter,
  createWebHistory,
  RouteRecordRaw
} from 'vue-router'
import Home from '../views/Home.vue'
import dashboardChildren from '@/router/dashboard_children';

import 'vue-router'
import NotFound from '@/views/NotFound.vue';
import i18n, {loadLanguageAsync} from '@/i18n';
import {store} from '@/store';
import {SET_LAYOUT_CONFIG} from '@/store/layout_module';

interface PageTitleConfig {
  showPageTitle: boolean
  showBackButton: boolean
  pageTitle: string
  pageTitleParams: any
  backButtonLink: string
  showFooterNav: boolean
}

declare module 'vue-router' {
  interface RouteMeta {
    pageTitleConfig: PageTitleConfig
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      pageTitleConfig: {
        showPageTitle: false,
        showBackButton: false,
        pageTitle: '',
        pageTitleParams: null,
        backButtonLink: '',
        showFooterNav: false
      }
    }
  },
  /**
   * Default entry page - processes the token and sets user data into the store
   */
  {
    name: 'dashboard',
    path: '/psp/:token',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    props: true,
    children: dashboardChildren,
  },

  /**
   * Not Found - any other route
   */

  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound,
    meta: {
      pageTitleConfig: {
        showPageTitle: false,
        showBackButton: false,
        pageTitle: '',
        pageTitleParams: null,
        backButtonLink: '',
        showFooterNav: false
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash
          // , offset: { x: 0, y: 10 }
        };
      } else {
        return {top: 0};
      }
    }
  }
  /*
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition: ScrollLogicalPosition | null) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash
          // , offset: { x: 0, y: 10 }
        };
      } else {
        return {x: 0, y: 0};
      }
    }
  },*/
})

router.beforeEach((to, from, next) => {
  if (to.params.lang) {
    const language = to.params.lang instanceof Array ? to.params.lang[0] : to.params.lang;
    loadLanguageAsync(language).then(() => next()).catch(() => {
      next({path: '/not-found'});
    });
  } else {
    loadLanguageAsync(i18n.global.locale).then(() => next()).catch(() => {
      next({path: '/not-found'});
    });
  }
})

/**
 * After each route adjust the layout settings based on route meta data
 */
router.afterEach((to) => {
  store.commit('layout/' + SET_LAYOUT_CONFIG, to.meta.pageTitleConfig);
  if (to.name !== 'participantHome') {
    // page(to.fullPath);
  }
})

export default router
