import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import moment from 'moment';
import axios from 'axios'

import layoutModule from "./layout_module";
import {loadLanguageAsync} from '@/i18n';
import {ParticipantData} from '@/model/participant-data';

// define your typings for the store state
export interface State {
  count: number
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()


export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_DATA = 'SET_DATA';
export const SET_UNIT = 'SET_UNIT';
export const SET_DEBUG = 'SET_DEBUG';
export const SET_LANGUAGE_LOADED = 'SET_LANGUAGE_LOADED';
export const SET_LOADING = 'SET_LOADING';
export const SET_PRACTICE_MEDICINE = 'SET_PRACTICE_MEDICINE';
export const SET_PICK_TOOL_MEDICINE = 'SET_PICK_TOOL_MEDICINE';
export const SET_WATCH_DEMO_MEDICINE = 'SET_WATCH_DEMO_MEDICINE';
export const SET_SEEN_MEDICATIONS = 'SET_SEEN_MEDICATIONS';
export const SET_MEDICINES_TAB = 'SET_MEDICINES_TAB';
export const SET_SEEN_WELCOME = 'SET_SEEN_WELCOME';

const SEEN_MEDICATIONS_KEY = 'nicu_seenMedications';
const SEEN_WELCOME_KEY = 'nicu_seenWelcome';

const seenMedications = () => {
  const stored = localStorage.getItem(SEEN_MEDICATIONS_KEY);
  if (stored !== null) {
    return JSON.parse(stored);
  }
  return false;
}
const seenWelcome = () => {
  const stored = localStorage.getItem(SEEN_WELCOME_KEY);
  if (stored !== null) {
    return JSON.parse(stored);
  }
  return false;
}

export const store =  createStore({
  modules: {
    layout: layoutModule
  },
  state: {
    lang: 'en',
    debug: false,
    languageLoaded: false,
    loading: false,
    token: '',
    data: {} as ParticipantData,
    unit: 'standard',
    practiceMedicine: null,
    watchDemoMedicine: null,
    pickToolMedicine: null,
    seenMedications: seenMedications(),
    medicinesTab: 'list',
    seenWelcome: seenWelcome(),
  },
  getters: {
    langAndTokenSet: (state) => {
      return state.lang && state.token;
    },
    debug: (state) => {
      return state.debug
    },
    loading: (state) => {
      return state.loading
    },
    languageLoaded: (state) => {
      return state.languageLoaded
    },
    participantData: (state) => {
      return state.data as ParticipantData
    },
    lang: (state) => {
      if (state.data) {
        return state.data.language
      }
      return 'en';
    },
    practiceMedicineSelected: (state) => {
      return !!state.practiceMedicine
    },
    practiceMedicine: (state) => {
      return state.practiceMedicine
    },
    pickToolMedicineSelected: (state) => {
      return !!state.pickToolMedicine
    },
    pickToolMedicine: (state) => {
      return state.pickToolMedicine
    },
    watchDemoMedicineSelected: (state) => {
      return !!state.watchDemoMedicine
    },
    watchDemoMedicine: (state) => {
      return state.watchDemoMedicine
    },
    userDataSet: (state) => {
      return state.data !== null;
    },
    goalDetailById: (state: any) => (id: string) => {
      if (!state.data.goals || state.data.goals.length === 0) {
        return null;
      }
      if (id === 'latest') {
        return state.data.goals[0];
      }
      else {
        return state.data.goals.find((val: any) => {
          return val.goalKey.toString() === id.toString()
        });
      }
    },
    seenMedications: (state) => {
      return state.seenMedications;
    },
    medicinesTab: (state) => {
      return state.medicinesTab
    },
    seenWelcome: (state) => {
      return state.seenWelcome;
    },
  },
  mutations: {
    [SET_LANGUAGE](state, language) {
      state.lang = language;
      moment.locale(language);
    },
    [SET_TOKEN](state, token) {
      state.token = token;
    },
    [SET_DEBUG](state, debug) {
      state.debug = debug;
    },
    [SET_UNIT](state, unit) {
      state.unit = unit;
    },
    [SET_DATA](state, data) {
      state.data = data;
    },
    [SET_LANGUAGE_LOADED](state, loaded) {
      state.languageLoaded = loaded;
    },
    [SET_LOADING](state, loading) {
      state.loading = loading;
    },
    [SET_PRACTICE_MEDICINE](state, medicine) {
      state.practiceMedicine = medicine;
    },
    [SET_PICK_TOOL_MEDICINE](state, medicine) {
      state.pickToolMedicine = medicine;
    },
    [SET_WATCH_DEMO_MEDICINE](state, medicine) {
      state.watchDemoMedicine = medicine;
    },
    [SET_SEEN_MEDICATIONS](state, seen) {
      state.seenMedications = seen;
      localStorage.setItem(SEEN_MEDICATIONS_KEY, seen);
    },
    [SET_MEDICINES_TAB](state, tab) {
      state.medicinesTab = tab;
    },
    [SET_SEEN_WELCOME](state, seen) {
      state.seenWelcome = seen;
      localStorage.setItem(SEEN_WELCOME_KEY, seen);
    },
  },
  actions: {
    async getParticipantData({commit, state}, params) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/participants/'+state.token, {
          params
        });
        if (response.data && response.data.id){

          const participantData = response.data;

          commit(SET_DATA, participantData);
          commit(SET_LANGUAGE, participantData.language);

          loadLanguageAsync(participantData.language, true);

          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }

      } catch (e) {
        return Promise.reject(e)
      }
    },

    async takeDose({state}, doseID) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/participants/' + state.token + '/take-dose/' + doseID);
        if (response.data && response.data.id){
          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getDose({commit, state}, doseID) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/participants/'+state.token + '/dose/' + doseID);
        if (response.data && response.data.id){
          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getMedicine({commit, state}, medicineID) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/participants/'+state.token + '/medicine/' + medicineID);
        if (response.data && response.data.id){
          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async saveToolForMedicine({commit, state}, medicineData) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/participants/'+state.token + '/medicine/' + medicineData.medicineID + '/tool', medicineData.tool);
        if (response.data && response.data.id){
          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async saveViewedDemoForMedicine({commit, state}, medicineData) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/participants/'+state.token + '/medicine/' + medicineData.medicineID + '/viewed-demo', {});
        if (response.data && response.data.id){
          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async savePracticedForMedicine({commit, state}, medicineData) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/participants/'+state.token + '/medicine/' + medicineData.medicineID + '/practiced', {});
        if (response.data && response.data.id){
          return Promise.resolve(response.data)
        }else{
          return Promise.reject(response.data)
        }
      } catch (e) {
        return Promise.reject(e)
      }
    },

  }
})
