import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

let storedScrollY;

export default {
    install: ( app, options ) => {
        app.directive('scroll-lock', {

            // On mount (inserted)
            mounted( el, binding ) {
                //console.log(binding.value);
                if (binding.value) {
                    storedScrollY = window.scrollY;
                    disableBodyScroll(el,  {
                        allowTouchMove: el => {
                            while (el && el !== document.body) {
                                if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                                    return true;
                                }

                                el = el.parentElement;
                            }
                        },
                    });
                    document.body.style.setProperty("top", `${window.scrollY * -1}px`);
                }
            },

            updated( el, binding ) {
                if (binding.value) {
                    storedScrollY = window.scrollY;
                    disableBodyScroll(el,  {
                        allowTouchMove: el => {
                            while (el && el !== document.body) {
                                if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                                    return true;
                                }

                                el = el.parentElement;
                            }
                        },
                    });
                    document.body.style.setProperty("top", `${window.scrollY * -1}px`);
                } else {
                    enableBodyScroll(el)
                    document.body.style.setProperty("top", "");
                    document.body.scrollTo(0, storedScrollY);
                }
            },

            // On unmount (removed)
            unmounted( el ) {
                enableBodyScroll(el)
                document.body.style.setProperty("top", "");
                document.body.scrollTo(0, storedScrollY);
            }
        })
    },
}
