
// import moment from 'moment';
import DaysCarousel from '@/components/views/medicines/track-medicines/DaysCarousel.vue';
import TimeOfDay from '@/components/views/medicines/track-medicines/TimeOfDay.vue';
import {ParticipantData} from '@/model/participant-data';
import { defineComponent } from 'vue'
import {ScheduleDay} from '@/model/dose';
import { useCookies } from "vue3-cookies";
import Popup from '@/components/ui/Popup.vue';

export default defineComponent({
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
  },
  components: {
    DaysCarousel,
    TimeOfDay,
    Popup
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    participantDays(): ScheduleDay[] {
      return this.participantData.days;
    },
    selectedDay(): ScheduleDay | undefined | null {
      if (!this.selectedDate || !this.participantData) return null;
      return this.participantDays.find((d: ScheduleDay) => {
        return d.day === this.selectedDate;
      })
    }
  },
  data() {
    return {
        selectedDate: '',
        infoVisible: false
    }
  },
  mounted() {

    let hasSeenInstructions = this.cookies.get('MEDICATIONS_SEEN_INSTRUCTIONS');
    if (!hasSeenInstructions) {
      this.cookies.set('MEDICATIONS_SEEN_INSTRUCTIONS', '1', 60 * 60 * 24 * 7);
      setTimeout(() => {
        this.infoVisible = true
      }, 300);
    }
  },
  methods: {
    onDateSelect(date) {
      this.selectedDate = date;
    },
  }
})
