
import {ParticipantData, ParticipantMedicine} from "@/model/participant-data";
import {Dose} from '@/model/dose';
import {defineComponent} from 'vue';
import {SET_PICK_TOOL_MEDICINE, SET_PRACTICE_MEDICINE, SET_WATCH_DEMO_MEDICINE} from '@/store';
import eventBus from '@/plugins/eventBus';

export default defineComponent({
  name: "medicine",
  props: {
    medicineData: {
      type: Object as () => ParticipantMedicine,
      required: true
    }
  },
  data() {
    return {
      medicine: {} as ParticipantMedicine
    }
  },
  mounted() {
    this.medicine = this.medicineData;
    eventBus.$on(eventBus.MEDICINE_UPDATE, (medicine) => {
      if (medicine.id === this.medicine.id)
        this.medicine = medicine
    });
  },
  unmounted() {
    eventBus.$off(eventBus.MEDICINE_UPDATE);
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lang(): string {
      return this.participantData.language;
    },
    digitsNeeded() {
      let digits = 0;
      let amount = parseFloat(this.medicine.dose);
      while (digits < 3 && Math.floor(amount) != amount) {
        digits++;
        amount = amount * 10;
      }
      return digits;
    },
    doseFormatted() {
      return parseFloat(this.medicine.dose).toFixed(this.digitsNeeded);
    },
    hasToolSelected() {
      return !!this.medicine.selected_tool;
    },
    watchedDemo() {
      return !!this.medicine.watched_demo_at;
    },
    practiceDone() {
      return !!this.medicine.practiced_giving_at;
    },
  },
  methods: {
    pickTool() {
      this.$store.commit(SET_PICK_TOOL_MEDICINE, this.medicine.id);
    },
    watchDemo() {
      if (this.hasToolSelected) {
        this.$store.commit(SET_WATCH_DEMO_MEDICINE, this.medicine.id);
      }
    },
    practiceGiving() {
      //console.log(this.medicine);
      if (this.hasToolSelected && this.watchedDemo) {
        this.$store.commit(SET_PRACTICE_MEDICINE, this.medicine.id);
      }
    }
  }
})
