import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/loading.gif'


const _hoisted_1 = { class: "item" }
const _hoisted_2 = {
  key: 0,
  class: "medicine"
}
const _hoisted_3 = {
  key: 0,
  class: "icon-check"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_0
}
const _hoisted_5 = { class: "medicine-name" }
const _hoisted_6 = {
  key: 1,
  class: "alt-name"
}
const _hoisted_7 = {
  key: 2,
  class: "dosage"
}
const _hoisted_8 = { class: "instruction" }
const _hoisted_9 = {
  key: 3,
  class: "dosage"
}
const _hoisted_10 = { class: "instruction" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dose && _ctx.dose.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.canBeTaken)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass(["take-checkbox", {'taken': _ctx.dose.is_taken}]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.takeDose()))
              }, [
                (_ctx.dose.is_taken && !_ctx.loading)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.dose.submedication['label_' + _ctx.lang]), 1),
          (_ctx.dose.submedication.medication['known_as_' + _ctx.lang])
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('MEDICINES.ALSO_CALLED')) + " " + _toDisplayString(_ctx.dose.submedication.medication['known_as_' + _ctx.lang]), 1))
            : _createCommentVNode("", true),
          (_ctx.dose.dose)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.doseFormatted) + " mL ", 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.dose['how_to_take_' + _ctx.lang]), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.dose['dose_instructions_' + _ctx.lang]), 1)
              ]))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_link, {
      to: {name: 'dashboard.medicines.detail', params: { medicineId: _ctx.dose.participant_medication_id }},
      class: "btn btn-md py-2 rounded-pill btn-primary mt-3"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('MEDICINES.BUTTONS.MEDICINE_INFO')), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}