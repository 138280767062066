
import { defineComponent } from 'vue'
import {ParticipantData} from '@/model/participant-data';
import {Appointment} from '@/model/appointment';
import moment from 'moment';

export default defineComponent({
  props: {
    appointment: {
      type: Object as () => Appointment,
      required: true
    }
  },
  components: {
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lang(): string {
      return this.participantData.language;
    },
    formattedDayName(): string {
      return moment(this.appointment.schedule_date).format('dddd, LL');
    },
    formattedTime(): string {
      return moment(this.appointment.schedule_date).format('h:mm a');
    },
  },
  methods: {
  }
})
