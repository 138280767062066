

import moment from 'moment';
import { defineComponent } from 'vue'
import {  Dose } from '@/model/dose';
import {ParticipantData} from '@/model/participant-data';
import {SET_PRACTICE_MEDICINE} from '@/store';

export default defineComponent({
  props: {
    dose: {
      type: Object as () => Dose,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    digitsNeeded() {
      let digits = 0;
      let amount = parseFloat(this.dose.dose);
      while (digits < 3 && Math.floor(amount) != amount) {
        digits++;
        amount = amount * 10;
      }
      return digits;
    },
    doseFormatted() {
      return parseFloat(this.dose.dose).toFixed(this.digitsNeeded);
    },
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lang(): string {
      return this.participantData.language;
    },
    canBeTaken(): boolean {
      const today = moment();
      const doseDate = moment(this.dose.schedule_date);
      const difference = today.diff(doseDate, 'hours');
      return difference >= -1;
    },
    canBePracticed() {
      return this.dose.submedication.formulationType.has_dosing_tool;
    }
  },
  methods: {
    takeDose() {
      if (this.dose) {
        this.loading = true;

        this.$store.dispatch('takeDose', this.dose.id).then((response) => {
          this.loading = false;
          this.$emit('taken', response.data);
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });

      }
    },

    practiceMedicine() {
      //console.log(this.dose);
      this.$store.commit(SET_PRACTICE_MEDICINE, this.dose.id);
    }

  }
})
