
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
  },
  components: {
  },
  computed: {
    helpContacts(): [] {
      if (this.$store.state.data)
        return this.$store.state.data.help_contacts || []
      else return [];
    }
  },
  methods: {
  }
})
