
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
  },
  components: {
  },
  computed: {
    langAndTokenSet() {
      return this.$store.getters.langAndTokenSet;
    },
    userDataSet() {
      return this.$store.getters.userDataSet;
    },
    userData() {
      return this.$store.state.data;
    }
  },
  methods: {
  }
})
