

import moment from 'moment';
import { defineComponent, ref } from 'vue'
import { Carousel, Slide } from 'vue3-carousel';
import { ParticipantData } from '@/model/participant-data';
import DaysCarouselDay from './DaysCarouselDay.vue';
import {ScheduleDay} from '@/model/dose';

export default defineComponent({
  setup() {
    const carousel = ref<Carousel>(null)

    return {
      carousel
    }
  },
  props: {
  },
  components: {
    Carousel,
    Slide,
    DaysCarouselDay
  },
  computed: {
    participantDays(): ScheduleDay[] {
      return this.participantData.days;
    },
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    selectedDateIndex(): number {
      if (this.selectedDate !== '' && this.participantDays.length) {
        return this.participantDays.findIndex((el: ScheduleDay) => {
          return el.day === this.selectedDate;
        })
      }
      return -1;
    },
    todaysIndex(): number {
      if (this.participantDays.length) {
        const today = moment().format('YYYY-MM-DD');
        return this.participantDays.findIndex((el: ScheduleDay) => {
          return el.day === today;
        })
      }
      return -1;
    },
    selectedDateDisplay(): string {
      if (this.selectedDate) {
        const today = moment().hour(0).minute(0).seconds(0);
        const date = moment(this.selectedDate);
        const hoursDifference = today.diff(date, 'hours');
        if ( hoursDifference === 0) {
          return this.capitalizeFirstLetter(moment(this.selectedDate).format('[' +this.$t('MEDICINES.TODAY') + '], LL'))
        }
        else if (hoursDifference === 24) {
          return this.capitalizeFirstLetter(moment(this.selectedDate).format('[' +this.$t('MEDICINES.YESTERDAY') + '], LL'))
        }
        else if (hoursDifference === -23) {
          return this.capitalizeFirstLetter(moment(this.selectedDate).format('[' +this.$t('MEDICINES.TOMORROW') + '], LL'))
        }
        else {
          return this.capitalizeFirstLetter(moment(this.selectedDate).format('dddd, LL'))
        }
      }
      return '';
    }
  },
  data() {
    return {
      initialSlide: 0,
      settings: {
        itemsToShow: 5.5,
        snapAlign: 'center',
        //modelValue: 10
      },
      breakpoints: {
        // 320 and up
        320: {
          itemsToShow: 5.5
        },
        // 567 and up
        567: {
          itemsToShow: 7.5,
        },
      },
      selectedDate: ''
    }
  },
  mounted() {
    if (this.participantDays.length) {
      if (this.todaysIndex !== -1) {
        this.selectedDate = this.participantDays[this.todaysIndex].day;
        this.initialSlide = this.todaysIndex;
      }
      else {
        const today = moment();
        this.selectedDate = this.participantDays[this.participantDays.length - 1].day;
        let selectedDateDay = moment(this.selectedDate);

        // If today is in the future, select last day
        if (today > selectedDateDay) {
          this.initialSlide = this.participantDays.length - 1;
        }
        else {
          // Otherwise select first day
          this.selectedDate = this.participantDays[0].day;
          this.initialSlide = 0;
        }
      }
      this.$emit('select',this.selectedDate);
      //this.scrollToSelectedDate();
    }
  },
  methods: {
    //
    // get days(): any[] {
    //   return [];
    // }
    capitalizeFirstLetter(string):string {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    scrollToSelectedDate() {
      if (this.selectedDateIndex !== -1)
        this.carousel.slideTo(this.selectedDateIndex);
    },

    onDayClick(date: string) {
      this.selectedDate = date;
      this.scrollToSelectedDate();
      this.$emit('select',this.selectedDate);
    }
  }
})
