
import { defineComponent } from 'vue'

export default defineComponent({
  props: {

  },
  components: {
  },
  computed: {
  },
  methods: {
  }
})
