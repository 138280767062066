

// import moment from 'moment';
import { defineComponent } from 'vue'
import { ScheduleDay, TimeOfDay} from '@/model/dose';
import {ParticipantData} from '@/model/participant-data';
import TimeOfDayBox from '@/components/views/medicines/track-medicines/TimeOfDay.vue';
import moment from 'moment';
// import AgeLabel from '@/components/ui/AgeLabel.vue';
import WeightLabel from '@/components/ui/WeightLabel.vue';
import ParticipantAppointment from '@/components/views/appointments/ParticipantAppointment.vue';
import {SET_UNIT} from '@/store';
import {Weight} from '@/model/weight';
import {Appointment} from '@/model/appointment';

export default defineComponent({
  props: {
  },
  components: {
    //AgeLabel,
    WeightLabel,
    TimeOfDayBox,
    ParticipantAppointment
  },
  data() {
    return {
    }
  },
  computed: {
    unit(): string {
      return this.$store.state.unit
    },
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lastUpdated(): string {
      if (!this.latestWeight) return '';
      //return this.latestWeight.record_date.substring(0,10);
      const lastUpdated = moment(this.latestWeight.record_date.substr(0,10));
      return lastUpdated.format('ll')
    },
    // ageInMonths(): number {
    //   if (!this.latestWeight) return 0;
    //   const dob = moment(this.participantData.dob).startOf('day')
    //   const lastWeightDate = moment(this.latestWeight.record_date).startOf('day');
    //   return lastWeightDate.diff(dob,'months');
    // },
    // ageInWeeks(): number {
    //   if (!this.latestWeight) return 0;
    //   const dob = moment(this.participantData.dob).startOf('day');
    //   const lastWeightDate = moment(this.latestWeight.record_date).startOf('day');
    //   return lastWeightDate.diff(dob,'weeks');
    // },
    // ageInDays(): number {
    //   if (!this.latestWeight) return 0;
    //   const dob = moment(this.participantData.dob).startOf('day');
    //   const lastWeightDate = moment(this.latestWeight.record_date).startOf('day');
    //   return Math.ceil(lastWeightDate.diff(dob,'days', true));
    // },
    // ageInMonthsAndDaysFromDateOfBirth(): any {
    //   if (!this.latestWeight) return null;
    //   const dob = moment(this.participantData.dob).startOf('day');
    //   const lastWeightDate = moment(this.latestWeight.record_date).startOf('day');
    //   const months = lastWeightDate.diff(dob,'months');
    //   const days = Math.ceil(lastWeightDate.subtract(months, 'months').diff(dob, 'days', true));
    //   return {months, days};
    // },
    latestWeight(): Weight | null {
      if (!this.participantData.weights.length) return null;
      return this.participantData.weights[0];
    },
    now(): string {
      return moment().format('LL')
    },
    nextTimeOfDayToday(): TimeOfDay | null {
      const today = moment().format('YYYY-MM-DD');
      let todayDoses = this.participantData.days.filter((d: ScheduleDay) => {
        return d.day === today;
      });
      if (todayDoses.length) {
        const now = moment();
        const selectedDay = todayDoses[0];
        for (var i = 0; i < selectedDay.times_of_day.length; i++) {
          const timeOfDayTime = moment().hour(selectedDay.times_of_day[i].hour).minute(selectedDay.times_of_day[i].minute).second(0);
          if (timeOfDayTime > now) return selectedDay.times_of_day[i];
        }
      }
      return null;
    },
    nextAppointment(): Appointment | null {
      const futureAppointments = this.participantData.appointments.filter((app: Appointment) => {
        return !app.is_past;
      })
      if (futureAppointments.length) {
        return futureAppointments[0];
      }
      return null;
    }
  },
  methods: {
    toggleUnit() {
      if (this.unit === 'standard') {
        this.$store.commit(SET_UNIT, 'metric');
      }
      else {
        this.$store.commit(SET_UNIT, 'standard');
      }
    }
  }
})
