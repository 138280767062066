import { createI18n } from 'vue-i18n'
import axios, {AxiosError, AxiosResponse} from 'axios';
import {SET_LANGUAGE_LOADED, SET_LOADING, store} from '@/store';

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en'
})


const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string) {
    i18n.global.locale = lang;
    return lang;
}

export function loadLanguageAsync(lang: string, final = false) {
    if (loadedLanguages.includes(lang)) {
        if (i18n.global.locale !== lang) {
            setI18nLanguage(lang)
        }
        if (final) {
            store.commit(SET_LANGUAGE_LOADED, true);
        }
        return Promise.resolve()
    }
    store.commit(SET_LOADING, true);
    return axios.get(process.env.VUE_APP_API_URL + `/i18n/${lang}`).then((response: AxiosResponse) => {
        const msgs = response.data;
        loadedLanguages.push(lang)
        i18n.global.setLocaleMessage(lang, msgs.data);
        if (final) {
            store.commit(SET_LANGUAGE_LOADED, true);
        }
        store.commit(SET_LOADING, false);
        setI18nLanguage(lang)
    }).catch((error: AxiosError) => {
        store.commit(SET_LOADING, false);
        loadedLanguages.push(lang);
    });
}

export default i18n;
