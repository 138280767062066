import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/medicine-example_en.png'
import _imports_1 from '@/assets/images/medicine-example_es.png'


const _hoisted_1 = { class: "page info d-flex flex-column with-footer-bar with-tabs" }
const _hoisted_2 = { class: "content px-0 days-carousel-wrapper" }
const _hoisted_3 = { class: "content flex-grow-1" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "info" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "m-auto d-block mb-4"
}
const _hoisted_8 = {
  key: 1,
  src: _imports_1,
  alt: "",
  class: "m-auto d-block mb-4"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  class: "empty-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_days_carousel = _resolveComponent("days-carousel")!
  const _component_popup = _resolveComponent("popup")!
  const _component_time_of_day = _resolveComponent("time-of-day")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_days_carousel, {
        onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDateSelect($event)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.infoVisible)
            ? (_openBlock(), _createBlock(_component_popup, { key: 0 }, {
                header: _withCtx(() => [
                  _createElementVNode("h2", {
                    innerHTML: _ctx.$t('MEDICINES.POPUP.TITLE')
                  }, null, 8, _hoisted_4)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", {
                      class: "text-center",
                      innerHTML: _ctx.$t('MEDICINES.POPUP.INFO_1')
                    }, null, 8, _hoisted_6),
                    (_ctx.lang === 'en')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                      : _createCommentVNode("", true),
                    (_ctx.lang === 'es')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "text-center",
                      innerHTML: _ctx.$t('MEDICINES.POPUP.INFO_2')
                    }, null, 8, _hoisted_9),
                    _createElementVNode("a", {
                      class: "btn btn-md py-2 rounded-pill btn-primary d-block",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.infoVisible = false))
                    }, _toDisplayString(_ctx.$t('MEDICINES.POPUP.GOT_IT')), 1)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.selectedDay)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDay.times_of_day, (timeOfDay) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "box-card",
                key: timeOfDay.key
              }, [
                _createVNode(_component_time_of_day, { "time-of-day": timeOfDay }, null, 8, ["time-of-day"])
              ]))
            }), 128)),
            (!_ctx.selectedDay.times_of_day.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('MEDICINES.NO_DOSES_MESSAGE')), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}