
import moment from 'moment';
import { defineComponent } from 'vue'
import {
  MEDICATION_TYPE_DOCTOR_CALL,
  MEDICATION_TYPE_SHORT_TERM,
  ParticipantData,
  ParticipantMedicine
} from '@/model/participant-data';
import {SET_PICK_TOOL_MEDICINE, SET_PRACTICE_MEDICINE, SET_WATCH_DEMO_MEDICINE} from '@/store';
import eventBus from '@/plugins/eventBus';

export default defineComponent({
  props: {

  },
  components: {
  },
  computed: {
    loaded():boolean {
      return !!this.medicine && !!this.medicine.id
    },
    canBePracticed(): boolean {
      return this.medicine.submedication.formulationType.has_dosing_tool;
    },
    digitsNeeded(): number {
      if (this.medicine) {
        let digits = 0;
        let amount = parseFloat(this.medicine.dose);
        while (digits < 3 && Math.floor(amount) != amount) {
          digits++;
          amount = amount * 10;
        }
        return digits;
      }
      else return 1;
    },
    doseFormatted(): string {
      if (this.medicine) {
        return parseFloat(this.medicine.dose).toFixed(this.digitsNeeded);
      }
      else return '';
    },
    startDateFormatted(): string {
      if (this.medicine) {
        let date = moment(this.medicine.start_date);
        return date.format('MMM D');
      }
      else return '';
    },
    endDateFormatted(): string {
      if (this.medicine && this.medicine.end_date) {
        let date = moment(this.medicine.end_date);
        return date.format('MMM D');
      }
      else return '';
    },
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lang(): string {
      return this.participantData.language;
    },
    hasToolSelected(): boolean {
      return !!this.medicine.selected_tool;
    },
    watchedDemo(): boolean {
      return !!this.medicine.watched_demo_at;
    },
    practiceDone(): boolean {
      return !!this.medicine.practiced_giving_at;
    },
    medicineIsShortTerm(): boolean {
      if (this.medicine) {
        return this.medicine.medication_type.type  === MEDICATION_TYPE_SHORT_TERM
      }
      else return false;
    },
    medicineIsDoctorCall(): boolean {
      if (this.medicine) {
        return this.medicine.medication_type.type  === MEDICATION_TYPE_DOCTOR_CALL
      }
      else return false;
    }
  },
  beforeRouteEnter(to, from, next) {
    const medicineID = to.params.medicineId;
    next((vm) => {
      vm.fetchData(medicineID);
    })
  },
  data() {
    return {
      loading: false,
      error: false,
      medicine: {} as ParticipantMedicine
    }
  },
  mounted() {
    eventBus.$on(eventBus.MEDICINE_UPDATE, (medicine) => {
      if (medicine.id === this.medicine.id)
        this.medicine = medicine
    });
  },
  unmounted() {
    eventBus.$off(eventBus.MEDICINE_UPDATE);
  },
  methods: {
    fetchData(medicineID) {
      this.loading = true;
      this.$store.dispatch('getMedicine', medicineID).then((response) => {
        this.loading = false;
        this.medicine = response;
      }).catch(() => {
        this.loading = false;
        this.error = true;
      })
    },

    timeString(timeOfDay) {
      let day = moment();
      let timeParts = timeOfDay.time.split(':');
      day.hours(parseInt(timeParts[0], 10));
      day.minutes(parseInt(timeParts[1], 10))
      return day.format('h:mm a')
    },

    timeHour(timeOfDay) {
      let day = moment();
      let timeParts = timeOfDay.time.split(':');
      return parseInt(timeParts[0], 10) % 12;
    },

    pickTool() {
      this.$store.commit(SET_PICK_TOOL_MEDICINE, this.medicine.id);
    },
    watchDemo() {
      if (this.hasToolSelected) {
        this.$store.commit(SET_WATCH_DEMO_MEDICINE, this.medicine.id);
      }
    },
    practiceGiving() {
      //console.log(this.medicine);
      if (this.hasToolSelected && this.watchedDemo) {
        this.$store.commit(SET_PRACTICE_MEDICINE, this.medicine.id);
      }
    }
  }
})
