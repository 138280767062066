import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "with-tabs" }
const _hoisted_2 = { class: "inner d-flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "tabs-wrapper" }
const _hoisted_4 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_track_medicines = _resolveComponent("track-medicines")!
  const _component_list_medicines = _resolveComponent("list-medicines")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('MEDICINES.PAGE_TITLE')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            class: _normalizeClass(["tab", {'active': _ctx.listSelected}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTab('list')))
          }, _toDisplayString(_ctx.$t('MEDICINES.LIST_OF_MEDICINES')), 3),
          _createElementVNode("a", {
            class: _normalizeClass(["tab", {'active': _ctx.trackSelected}]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTab('track')))
          }, _toDisplayString(_ctx.$t('MEDICINES.TRACK_MEDICINES')), 3)
        ])
      ])
    ]),
    (_ctx.trackSelected)
      ? (_openBlock(), _createBlock(_component_track_medicines, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.listSelected)
      ? (_openBlock(), _createBlock(_component_list_medicines, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}