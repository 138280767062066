
import {defineComponent, ref} from 'vue'

import AppHeader from '@/components/layout/AppHeader.vue';
import WelcomeSlide from '@/components/views/welcome/WelcomeSlide.vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import {SET_SEEN_WELCOME} from '@/store';

export default defineComponent({
  setup() {
    const carousel = ref<Carousel>(null)

    return {
      carousel
    }
  },
  props: {
  },
  components: {
    AppHeader,
    WelcomeSlide,
    Carousel,
    Slide,
    Pagination
  },
  computed: {
    seenWelcome(): boolean {
      return this.$store.getters.seenWelcome;
    },
    lastSlide(): boolean {
      if (this.carousel) {
        return this.carousel.data.currentSlide.value === this.reviewSlides.length - 1;
      }
      return false;
    }
  },
  data() {
    return {
      initialSlide: 0,
      reviewing: false,
      welcomeNotSeen: {
        image: '/images/welcome_child.png',
        title: 'WELCOME.NOT_SEEN.TITLE',
        description: 'WELCOME.NOT_SEEN.DESCRIPTION'
      },
      welcomeSeen: {
        image: '/images/welcome_child.png',
        title: 'WELCOME.SEEN.TITLE',
        description: 'WELCOME.SEEN.DESCRIPTION'
      },
      reviewSlides: [
        {
          image: '/images/welcome_medicine.png',
          title: 'WELCOME.REVIEW.0.TITLE',
          description: 'WELCOME.REVIEW.0.DESCRIPTION'
        },
        {
          image: '/images/welcome_tool.png',
          title: 'WELCOME.REVIEW.1.TITLE',
          description: 'WELCOME.REVIEW.1.DESCRIPTION'
        },
        {
          image: '/images/welcome_appointment.png',
          title: 'WELCOME.REVIEW.2.TITLE',
          description: 'WELCOME.REVIEW.2.DESCRIPTION'
        },
        {
          image: '/images/welcome_help.png',
          title: 'WELCOME.REVIEW.3.TITLE',
          description: 'WELCOME.REVIEW.3.DESCRIPTION'
        }
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        //modelValue: 10
      }
    }
  },
  methods: {
    startReview() {
      this.$store.commit(SET_SEEN_WELCOME, true);
      this.reviewing = true;
    },
    nextSlide() {
      this.carousel.next();
    }
  }
})
