import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'
import i18n from './i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'
import BodyScrollLock from '@/plugins/BodyScrollLock'
import Vue3TouchEvents from "vue3-touch-events";
import {mapGetters} from 'vuex'
import VCalendar from 'v-calendar';
import { createMetaManager } from 'vue-meta'
import moment from "moment";
import VueGtag from "vue-gtag";

const globalMixin1 = {
    computed: {
        ...mapGetters([
            'debug',
            'lang',
            'participantData'
        ]),
    }
}

moment.updateLocale("en", { longDateFormat : { LTS: 'h:mm:ss A',
        LT: 'h:mm A',
        L: 'MM/DD/YYYY',
        LL: 'MMMM D',
        LLL: 'MMMM D, YYYY h:mm A',
        LLLL: 'dddd, MMMM D, YYYY h:mm A'
} });
moment.updateLocale("es", { longDateFormat : {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM',
        LLL: 'D [de] MMMM [de] YYYY H:mm',
        LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm',
    } });

createApp(App)
    .use(store, key)
    .use(router)
    .use(i18n)
    .use(BodyScrollLock)
    .use(createMetaManager())
    .use(Vue3TouchEvents, {
        dragFrequency: 50
    })
    .use(VueAxios, axios)
    .use(VueGtag, {
        config: {
            id: process.env.VUE_APP_GA_ID,
        },
        pageTrackerUseFullPath: true,
        enabled: process.env.VUE_APP_ENABLE_GA === 'true',
    }, router)
    .use(VCalendar, {})
    .mixin(globalMixin1)
    .mount('#app')
