

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    unit: {
      type: String,
      default: 'standard'
    },
    kgValue: {
      type: Number,
      default: 0
    },
    decimal: {
      type: Boolean,
      default: false
    },
    abbreviated: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  computed: {

    lbValue() {
      const lbValue = Math.floor(this.kgValue * 2.205);
      const ozValue = (((this.kgValue * 2.205) - lbValue) * 16).toFixed(1)
      return {
        lb: lbValue,
        oz: ozValue
      }
    },

    ozValueInteger() {
      return Math.floor(parseFloat(this.lbValue.oz))
    },

    lbValueDecimal() {
      return Math.round(this.kgValue * 2.205);
    },

    lbValueDecimalFormatted() {
      return this.lbValueDecimal.toFixed(3);
    },

    kgNumeric() {
      return Number(this.kgValue);
    },

    kgValueDecimalFormatted() {
      return this.kgNumeric.toFixed(3);
    }
  },
  methods: {
  }
})
