import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "days-carousel" }
const _hoisted_2 = { class: "selected-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_days_carousel_day = _resolveComponent("days-carousel-day")!
  const _component_slide = _resolveComponent("slide")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_carousel, {
      settings: _ctx.settings,
      breakpoints: _ctx.breakpoints,
      modelValue: _ctx.initialSlide,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.initialSlide) = $event)),
      ref: "carousel"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participantDays, (day) => {
          return (_openBlock(), _createBlock(_component_slide, {
            key: day.day
          }, {
            default: _withCtx(() => [
              _createVNode(_component_days_carousel_day, {
                day: day,
                selected: _ctx.selectedDate === day.day,
                onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDayClick($event)))
              }, null, 8, ["day", "selected"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["settings", "breakpoints", "modelValue"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.selectedDateDisplay), 1)
  ]))
}