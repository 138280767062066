

import moment from 'moment';
import { defineComponent } from 'vue'
import { Dose, TimeOfDay } from '@/model/dose';
import {ParticipantData} from '@/model/participant-data';
import TimeOfDayDose from '@/components/views/medicines/track-medicines/TimeOfDayDose.vue';

export default defineComponent({
  props: {
    timeOfDay: {
      type: Object as () => TimeOfDay,
      required: true
    }
  },
  components: {
    TimeOfDayDose
  },
  data() {
    return {
      forceOpen: false
    }
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    lang(): string {
      return this.participantData.language;
    },
    isFullyTaken() {
      return this.timeOfDay.doses.filter((d: Dose) => {
        return d.is_taken
      }).length === this.timeOfDay.doses.length;
    },
    timeString() {
      let day = moment();
      let timeParts = this.timeOfDay.time.split(':');
      day.hours(parseInt(timeParts[0], 10));
      day.minutes(parseInt(timeParts[1], 10))
      return day.format('h:mm a')
    },
    timeHour() {
      let day = moment();
      let timeParts = this.timeOfDay.time.split(':');
      return parseInt(timeParts[0], 10) % 12;
    }

  },
  methods: {
    toggleForceOpen() {
      if (this.isFullyTaken) {
        this.forceOpen = !this.forceOpen;
      }
    },
    doseTaken(dose: Dose) {
      this.$store.dispatch('getParticipantData').then((response) => {
        console.log(response);
      })
    }
  }
})
