

import { defineComponent } from 'vue'
import TrackMedicines from '@/components/views/medicines/TrackMedicines.vue';
import ListMedicines from '@/components/views/medicines/ListMedicines.vue';
import {SET_MEDICINES_TAB, SET_SEEN_MEDICATIONS} from '@/store';

export default defineComponent({
  props: {
  },
  components: {
    TrackMedicines,
    ListMedicines
  },
  data() {
    return {
      infoVisible: false
    }
  },
  computed: {
    seenMedications(): boolean {
      return this.$store.getters.seenMedications;
    },
    selectedTab(): string {
      return this.$store.getters.medicinesTab;
    },
    trackSelected(): boolean {
      return this.selectedTab === 'track';
    },
    listSelected(): boolean {
      return this.selectedTab === 'list';
    }
  },
  mounted() {
    if (this.$route.query.resetTab) {
      this.selectTab('list');
    }
  },
  methods: {
    selectTab(tab: string) {
      this.$store.commit(SET_MEDICINES_TAB, tab)
    }
  }
})
