
import {defineComponent, ref} from 'vue'
import {ParticipantData} from '@/model/participant-data';
import {Appointment} from '@/model/appointment';
import ParticipantAppointment from '@/components/views/appointments/ParticipantAppointment.vue';
import Popup from '@/components/ui/Popup.vue';
import moment from 'moment';
export default defineComponent({
  props: {
  },
  components: {
    Popup,
    ParticipantAppointment
  },
  computed: {
    participantData(): ParticipantData {
      return this.$store.state.data;
    },
    appointments(): Appointment[] {
      return this.participantData.appointments;
    },
    filteredAppointments(): Appointment[] {
      if (this.selectedDate) {
        return this.appointments.filter((app: Appointment) => {
          return moment(this.selectedDate.toString()).format('YYYY-MM-DD') === app.date;
        });
      }
      return [];
    },
    filteredFutureAppointments(): Appointment[] {
      if (this.selectedDate) {
        return this.appointments.filter((app: Appointment) => {
          return moment(this.selectedDate.toString()).endOf('day') < moment(app.date);
        });
      }
      return [];
    },
    nextAppointment(): Appointment | null {
      const futureAppointments = this.appointments.filter((app: Appointment) => {
        return !app.is_past;
      })
      if (futureAppointments.length) {
        return futureAppointments[0];
      }
      return null;
    },
    selectedDateFormatted(): string {
      return moment(this.selectedDate.toString()).format('dddd, LL');
    },
    selectedDateString(): string {
      return moment(this.selectedDate.toString()).format('YYYY-MM-DD');
    },
    calendarAttributes(): any[] {
      let dates: any[] = [];
      dates = this.appointments.map((app: Appointment) => {
        return {
          key: app.date,
          highlight: app.date === this.selectedDateString,
          dot: {
            style: {
              'background-color': app.is_past ? '#B1B1B1' : '#1EB957',
            },
          },
          dates: new Date(app.schedule_date)
        }
      })
      dates.push({
        key: 'select',
        highlight: {
          contentClass: 'selected-day'
        },
        dates: this.selectedDate
      });
      let yesterday = moment().subtract(1,'day').format('YYYY-MM-DD');
      dates.push({
        highlight: {
          contentClass: 'past-day'
        },
        dates: { start: new Date(1900, 0, 0), end: new Date(yesterday) }
      })
      return dates;
    }
  },
  watch: {
    selectedDate(value, old) {
      if (!value) {
        //this.selectedDate = new Date();
        //this.calendar.focusDate(this.selectedDate);
      }
    }
  },
  data() {
    return {
      selectedDate: new Date(),
      infoVisible: false
    }
  },
  mounted() {
    if (!this.nextAppointment) {
      this.infoVisible = true;
    }
  },
  methods: {
    onDayClick(day) {
      this.selectedDate = new Date(day.date);
    }
  }
})
