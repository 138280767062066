import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "welcome-slide" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.slide.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.slide.image
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.slide.title)
      ? (_openBlock(), _createElementBlock("h3", {
          key: 1,
          innerHTML: _ctx.$t(_ctx.slide.title)
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.slide.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          innerHTML: _ctx.$t(_ctx.slide.description)
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}