import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "page info" }
const _hoisted_2 = { class: "content content-welcome" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_welcome_slide = _resolveComponent("welcome-slide")!
  const _component_slide = _resolveComponent("slide")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_header),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.seenWelcome && !_ctx.reviewing)
          ? (_openBlock(), _createBlock(_component_welcome_slide, {
              key: 0,
              slide: _ctx.welcomeNotSeen
            }, null, 8, ["slide"]))
          : _createCommentVNode("", true),
        (_ctx.seenWelcome && !_ctx.reviewing)
          ? (_openBlock(), _createBlock(_component_welcome_slide, {
              key: 1,
              slide: _ctx.welcomeSeen
            }, null, 8, ["slide"]))
          : _createCommentVNode("", true),
        (_ctx.reviewing)
          ? (_openBlock(), _createBlock(_component_carousel, {
              key: 2,
              class: "other-tools-carousel",
              settings: _ctx.settings,
              modelValue: _ctx.initialSlide,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.initialSlide) = $event)),
              ref: "carousel"
            }, {
              addons: _withCtx(() => [
                _createVNode(_component_pagination)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviewSlides, (slide, index) => {
                  return (_openBlock(), _createBlock(_component_slide, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_welcome_slide, { slide: slide }, null, 8, ["slide"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["settings", "modelValue"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.seenWelcome && !_ctx.reviewing)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "btn btn-md py-2 rounded-pill btn-primary d-block",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.startReview()))
              }, _toDisplayString(_ctx.$t('WELCOME.BUTTONS.GET_STARTED')), 1))
            : _createCommentVNode("", true),
          (_ctx.seenWelcome && !_ctx.reviewing)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "btn btn-md py-2 rounded-pill btn-primary d-block",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.startReview()))
              }, _toDisplayString(_ctx.$t('WELCOME.BUTTONS.REVIEW_APP')), 1))
            : _createCommentVNode("", true),
          (_ctx.reviewing && _ctx.lastSlide)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: {name: 'dashboard.home'},
                class: "btn btn-md py-2 rounded-pill btn-primary d-block"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('WELCOME.BUTTONS.SEE_INFO')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (!_ctx.lastSlide && (_ctx.reviewing || (!_ctx.reviewing && _ctx.seenWelcome)))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                to: {name: 'dashboard.home'},
                class: _normalizeClass(["skip", {'centered': !_ctx.reviewing}])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('WELCOME.BUTTONS.SKIP')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"]))
            : _createCommentVNode("", true),
          (_ctx.reviewing && !_ctx.lastSlide)
            ? (_openBlock(), _createElementBlock("a", {
                key: 4,
                class: "next",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
              }, _toDisplayString(_ctx.$t('WELCOME.BUTTONS.NEXT')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}