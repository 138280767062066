import {Weight} from '@/model/weight';
import {Dose, DosingTool, DosingToolGroup, ScheduleDay, Submedication} from '@/model/dose';
import {Appointment, AppointmentDay} from '@/model/appointment';

export interface ParticipantData {
    id: number;
    internal_id: string;
    web_id: string;
    is_active: boolean;
    language: string;
    affiliation: string;
    //phone: string;
    //dob: Date;
    created_at: string;
    updated_at: string;
    weights: Weight[];
    appointments: Appointment[];
    doses: Dose[];
    days: ScheduleDay[];
    appointment_days: AppointmentDay[];
    medications: ParticipantMedicine[]
}

export interface HowToTake {
    id: number;
    type: string;
    name_en: string;
    name_es: string;
}

export interface TimeLabel {
    id: number;
    type: string;
    name_en: string;
    name_es: string;
}

export interface FrequencyTime {
    id: number;
    time: string;
    hour: number;
    minute: number;
    first: boolean;
    timeLabel: TimeLabel;
}

export interface MedicationType {
    id: number;
    name_en: string;
    name_es: string;
    type: string;
    has_duration: boolean;
    has_tws: boolean;
}

export const MEDICATION_TYPE_SHORT_TERM = 'short-term';
export const MEDICATION_TYPE_DOCTOR_CALL = 'doctor-call';

export interface Frequency {
    id: number;
    times: number;
    instruction_en: string;
    instruction_es: string;
    frequencyInstructionTimes: FrequencyTime[];
}

export interface Indication {
    id: number;
    name_en: string;
    name_es: string;
    description_en: string;
    description_es: string;
}

export interface SideEffect {
    side_effect: {
        id: number;
        name_en: string;
        name_es: string;
    }
}

export interface ParticipantMedicine {
    id: number;
    participant_id: number;
    submedication_id: number;
    dose: string;
    dose_instructions_en: string;
    dose_instructions_es: string;
    start_date: string;
    end_date: string;
    duration: number;
    is_past: boolean;
    side_effects: SideEffect[];
    submedication: Submedication;
    how_to_take: HowToTake;
    frequency: Frequency;
    instruction_times: FrequencyTime[];
    doses: Dose[];
    selected_tool_at: string;
    selected_tool: DosingTool;
    medication_type: MedicationType;
    watched_demo_at: string;
    practiced_giving_at: string;
    recommended_tools: DosingToolGroup[];
    other_tools: DosingToolGroup[];
}
