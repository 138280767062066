import DashboardHome from '@/views/dashboard/DashboardHome.vue';
import DashboardWelcome from '@/views/dashboard/DashboardWelcome.vue';
import DashboardMedicines from '@/views/dashboard/DashboardMedicines.vue';
import DashboardHelp from '@/views/dashboard/DashboardHelp.vue';
import DashboardAppointments from '@/views/dashboard/DashboardAppointments.vue';
import DashboardMedicineDetail from '@/views/dashboard/DashboardMedicineDetail.vue';
import DashboardMedicinesWrapper from '@/views/dashboard/DashboardMedicinesWrapper.vue';

const dashboardChildren = [
    {
        path: 'home',
        name: 'dashboard.home',
        component: DashboardHome,
        props: true,
        meta: {
            pageTitleConfig: {
                showPageTitle: true,
                showBackButton: false,
                pageTitle: 'INTRO.PAGE_TITLE',
                pageTitleParams: null,
                backButtonLink: '',
                showFooterNav: true
            }
        },
    },
    {
        path: 'welcome',
        name: 'dashboard.welcome',
        component: DashboardWelcome,
        props: true,
        meta: {
            pageTitleConfig: {
                showPageTitle: false,
                showBackButton: false,
                pageTitle: '',
                pageTitleParams: null,
                backButtonLink: '',
                showFooterNav: false
            }
        }
    },
    {
        path: 'medicines',
        name: 'dashboard.medicines',
        props: true,
        component: DashboardMedicinesWrapper,
        redirect: { name: 'dashboard.medicines.home' },
        children: [
            {
                path: '',
                name: 'dashboard.medicines.home',
                component: DashboardMedicines,
                props: true,
                meta: {
                    pageTitleConfig: {
                        showPageTitle: true,
                        showBackButton: false,
                        pageTitle: 'MEDICINES.PAGE_TITLE',
                        pageTitleParams: null,
                        backButtonLink: '',
                        showFooterNav: true
                    }
                },
            },
            {
                path: ':medicineId',
                name: 'dashboard.medicines.detail',
                component: DashboardMedicineDetail,
                props: true,
                meta: {
                    pageTitleConfig: {
                        showPageTitle: true,
                        showBackButton: true,
                        pageTitle: 'MEDICINES.PAGE_TITLE',
                        pageTitleParams: null,
                        backButtonLink: '',
                        showFooterNav: true
                    }
                },
            },
        ]
    },
    {
        path: 'appointments',
        name: 'dashboard.appointments',
        component: DashboardAppointments,
        props: true,
        meta: {
            pageTitleConfig: {
                showPageTitle: true,
                showBackButton: false,
                pageTitle: 'APPOINTMENTS.PAGE_TITLE',
                pageTitleParams: null,
                backButtonLink: '',
                showFooterNav: true
            }
        }
    },
    {
        path: 'help',
        name: 'dashboard.help',
        component: DashboardHelp,
        props: true,
        meta: {
            pageTitleConfig: {
                showPageTitle: true,
                showBackButton: false,
                pageTitle: 'HELP.PAGE_TITLE',
                pageTitleParams: null,
                backButtonLink: '',
                showFooterNav: true
            }
        }
    }
];

export default dashboardChildren;
