
import { defineComponent } from 'vue'
import AppFooter from '@/components/layout/AppFooter.vue';
import Loading from '@/components/layout/Loading.vue';
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import { key } from './store'

export default defineComponent({
  setup () {
    let indexOfPsp = document.location.href.indexOf('/psp');
    let arr: RegExpExecArray | null | never[]  = [];
    let pspValue;
    if (indexOfPsp !== -1) {
      let psp = document.location.href.substring(indexOfPsp);
      let rx = /\/psp\/(.*).*/g;
      arr = rx.exec(psp);
      if (arr && arr.length) {
        pspValue = arr[1];
        if (pspValue.indexOf('?') !== -1) {
          pspValue = pspValue.substring(0, pspValue.indexOf('?'));
        }
      }
      if (pspValue.indexOf('/') !== -1) {
        pspValue = pspValue.substring(0, pspValue.indexOf('/'));
      }
    }
    useMeta({
      title: 'NICU App',
      htmlAttrs: { lang: 'en', amp: true },
      meta: [
        { charset: 'utf-8' }
      ],
      link: [
        { rel: 'manifest', href: process.env.VUE_APP_API_BASE_URL + 'manifest.json?psp='+ pspValue },
      ]
    })
  },
  props: {
  },
  components: {
    AppFooter,
    Loading
  },
  computed: {
    languageLoaded() {
      return this.$store.getters.languageLoaded;
    }
  },
  methods: {
  }
})
