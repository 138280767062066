

import moment from 'moment';
import { defineComponent } from 'vue'
import { ScheduleDay, Dose, TimeOfDay } from '@/model/dose';

export default defineComponent({
  props: {
    day: {
      type: Object as () => ScheduleDay,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  components: {
  },
  computed: {
    date(): string {
      return this.day.day;
    },
    formattedDayName(): string {
      return moment(this.date).format('ddd');
    },
    formattedDay(): string {
      return moment(this.date).format('D');
    },
    hasDoses(): boolean {
      return this.day.times_of_day.length > 0;
    },
    isFuture(): boolean {
      const date = moment(this.date);
      const today = moment().hour(0).minute(0).second(0);
      return date >= today;
    },
    hasIncompleteDoses(): boolean {
      if (!this.hasDoses) return false;
      let timesOfDayWithUntakenDoses = this.day.times_of_day.filter((t: TimeOfDay) => {
        return t.doses.filter((d: Dose) => {
          return !d.is_taken;
        }).length > 0;
      })
      return timesOfDayWithUntakenDoses.length > 0;
    }
  },
  methods: {
    onDayClick() {
      this.$emit('select', this.day.day);
    }
  }
})
